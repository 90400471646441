import axios from "axios";
export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || null,
        user: JSON.parse(localStorage.getItem('user')) || null,
        role: '',
        loginFailure: false,
        buttonStatus: false,
        expires_in: '',
    },
    getters: {
        authenticated (state) {
            return state.token != null && state.user != null;
        },
        user (state) {
            return state.user;
        },
        loginFailure(state) {
            return state.loginFailure;
        },
        buttonStatus(state) {
            return state.buttonStatus;
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_EXPIRES_IN(state, expires_in) {
            state.expires_in = expires_in;
        },
        loginFailureData(state, loginFailure) {
            state.loginFailure = loginFailure;
        },
        clearAuthData(state) {
            state.token = null;
            state.user = null;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
          },
    },
    actions: {
        async login({ commit, dispatch }, authData) {
            let response = await axios.post('login', {
                email: authData.email,
                password: authData.password
            });

            let status = response.data.status;
            if (status == true) {
                const token = response.data.profile.api_token;
                const user = response.data.profile;

                // Store token and user in localStorage
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));

                // Set token in Axios headers
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            
                return dispatch('attempt', token);
             
            } else {
                commit('loginFailureData', true);
             
            }
        },
        async register({ commit, dispatch }, authData) {
            let response = await axios.post('register', {
                name: authData.name,
                email: authData.email,
                password: authData.password,
                type: authData.type,
                gender: authData.gender,
                disability_id: authData.disability_id,
                phone: authData.phone,
                age: authData.age,
            });

            let status = response.data.status;
            if (status == true) {
                const token = response.data.profile.api_token;
                const user = response.data.profile;

                // Store token and user in localStorage
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));

                // Set token in Axios headers
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
               
                return dispatch('attempt', token);
            } else {
                commit('loginFailureData', true);
            }
        },
        async attempt({ commit, state, dispatch }, token) {
            if (token) {
                commit('SET_TOKEN', token);
            } else {
                // Retrieve token from localStorage if it exists
                token = localStorage.getItem('token');
                if (token) {
                    commit('SET_TOKEN', token);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                }
            }

            // Retrieve user from localStorage
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                commit('SET_USER', user);
            }

            if (!state.token) {
                return;
            }

            return dispatch('profile');
        },
        async profile({ commit }) {
            try {
                let response = await axios.get('profile');
                if (response.data.status == true && response.data.result) {
                    const user = response.data.result;
                    commit('SET_USER', user);

                    // Store user data in localStorage
                    localStorage.setItem('user', JSON.stringify(user));
                } else {
                    commit('clearAuthData');
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
                commit('clearAuthData');
            }
        },
        async logout({ commit }) {
            commit('clearAuthData');
            // Remove axios Authorization header
            delete axios.defaults.headers.common['Authorization'];
            // Reload the page to reflect the changes
            window.location.reload();
          }
    }
}
