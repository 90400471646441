import { createRouter, createWebHistory } from 'vue-router'
// import axios                              from "../plugins/axios";

const routes = [
  
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../components/HomePage/index.vue')
  },
  {
    path: '/AllServices',
    name: 'AllServices',
    component: () => import('../components/AllServices/index.vue')
  }
  ,
  {
    path: '/ServiceDetails/:id',
    name: 'ServiceDetails',
    component: () => import('../components/ServiceDetails/index.vue')
  },
  {
    path: '/ShowSubServices/:id',
    name: 'ShowSubServices',
    component: () => import('../components/ShowSubServices/index.vue')
  }
 ,
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('../components/AboutUs/index.vue')
  } 
  ,
  {
    path: '/ConnectUs',
    name: 'ConnectUs',
    component: () => import('../components/ConnectUs/index.vue')
  } 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mood: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ?? { top:0}
   
  }
})

export default router
