import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "./plugins/axios";
import VueAxios from 'vue-axios'
import i18n from './i18n'
import Toast from 'vue-toast-notification';


// axios.defaults.baseURL =  process.env.VUE_APP_API;

let app = createApp(App)
app.provide('axios', app.config.globalProperties.axios)  // provide 'axios'
app.config.globalProperties.$axios = axios;


createApp(App).use(store).use(router).use(i18n).use(VueAxios, axios).use(Toast).mount('#app');

// store.dispatch('auth/attempt',localStorage.getItem('token')).then(() => {
//     app.use(i18n).use(Toaster,{  position: "top-right",duration:4000})
//     .use(store).use(router).mount('#app')
// })