<template>

    <app-header ></app-header>
    <router-view />
    <app-footer ></app-footer>



</template>
<script>

import AppHeader from '@/components/Shared/AppHeader.vue'
import AppFooter from '@/components/Shared/AppFooter.vue'
export default {
  name: 'app',
  components: {
    AppHeader,
    AppFooter
  },
  mounted(){
    console.log(process.env.VUE_APP_API);
    console.log("this.$route" + this.$route.name);
    
  },
  computed: {
      isLogin() {
        return this.$route.name === 'Login'
      }
    },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
