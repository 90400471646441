<template>
  <footer id="newsletter">
    <div class="container">
      <div class="row ">
        
        <div class="col-lg-12 offset-lg-2">
          <div class="section-heading">
            <h4>خطوه نحو حياه افضل</h4>
          </div>
        </div>
        <div class="col-lg-12 offset-lg-3 mb-4">
          <div class="w-50 text-center m-auto">
            <h3> تطوير نمط حياة الشباب من خلال محاور التنمية الشاملة الشبابية والرياضية بما يؤدي لتعزيز الانتماء والفخر
              بالهوية الوطنية وتطوير مهاراتهم وقدراتهم</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="footer-widget">
            <h4>تواصل معنا</h4>
            <p>Rio de Janeiro - RJ, 22795-008, Brazil</p>
            <p><a href="#">010-020-0340</a></p>
            <p><a href="#">info@company.co</a></p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="footer-widget">
            <h4>الخدمات العامه</h4>
            <ul class="w-100">
              <li v-for="(Service, id) in getServices" :key="id">
                <a href="#">{{ Service.name }}</a>
            </li>

            </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="footer-widget usful-links-col">
            <h4>روابط مفيده</h4>
            <ul class="usful-links">
              <li v-for="(Service, id) in getServices" :key="id">
                <p v-for="(subService, index) in Service.sub_category" :key="index">
                <a href="#">{{ subService.name }}</a>
              </p>
            </li>
            </ul>
            
          </div>
        </div>
        <div class="col-lg-3">
          <div class="footer-widget">
            <h4>عن مشروعنا</h4>
            <div class="logo">
              <img src="assets/images/webSite/logo.png" alt="" class="">
            </div>
            <p>الريادة والتميز في الارتقاء بجودة حياة الشباب المصري </p>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="copyright-text">
            <p>Copyright © 2024 <a href="https://programmesta.com/" target="_blank" title="www.programmesta.com"> www.programmesta.com</a> . All Rights Reserved.
              <br>Design: <a href="https://programmesta.com/" target="_blank" title="www.programmesta.com"> www.programmesta.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
  
<script>
import { mapGetters ,mapActions} from 'vuex';
export default {
  name: 'AppFooter ',
  props: {

  },
  data() {
    return {
      services: []

    }
  },
  computed: {
    ...mapGetters(['getServices']),
    // limitedServices() {
    //   return this.getServices.slice(0, 10);
    // }

  },
  mounted() {
    this.getServicesData();
    // this.getSubServicesData();
  },

  methods: {
    ...mapActions(['getServicesData']),

  }
}
</script>
  
  
<style scoped lang="scss">
.AppFooter {

  // background: linear-gradient(-225deg, #5D9FFF 0%, #B8DCFF 48%, #6BBBFF 100%);
  //  background: linear-gradient(-225deg, #5D9FFF 0%, #B8DCFF 48%, #6BBBFF 100%);
  background: linear-gradient(-225deg, #fca88d 0%, #a7ceea 10%,
      #f8c9b6 90%,
      #fcb0a1 80%,
      #f5f4ce 40%,
      #c9e5e4 20%,
      #fca88d 90%,
      #f9e8c5 70%);
  background-size: 400% 400%;
  animation: body 10s infinite ease-in-out;
}

@keyframes body {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px; 
            list-style-type: none; 
            padding: 0;
        }
        @media (max-width: 767px) {
  .usful-links-col {
    display: none;
  }
}
        
</style>
  