 <!-- eslint-disable -->
<template>
  <div class="AppHeader">
    <header class="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
      <div class="container">
        <div class="row">
          <div class="d-flex justify-content-between align-items-center">
            <!-- Logo -->
            <div class="logo">
              <router-link :to="{ name: 'HomePage' }" class="logo">
                <img src="/assets/images/webSite/logo.png" alt="Chain App Dev" style="width: 100px;" />
              </router-link>
            </div>

            <!-- Mobile Menu Button -->
            <button ref="menuButton" class="mobile-menu-btn d-block d-md-none" @click="toggleMobileMenu">
              <i class="fas fa-bars"></i>
            </button>

            <!-- Header Navigation and Dropdown -->
            <div class="d-none d-md-flex justify-content-between align-items-center w-100">
              <div class="headr-nav">
                <ul class="nav">
                  <li><router-link :to="{ name: 'HomePage' }">الرئيسيه</router-link></li>
                  <li><router-link :to="{ name: 'AllServices' }">الخدمات</router-link></li>
                  <li><router-link :to="{ name: 'AboutUs' }">معلومات عنا</router-link></li>
                  <li><router-link :to="{ name: 'ConnectUs' }">تواصل معنا</router-link></li>
                  <li v-if="!user">
                    <button class="btn btn-block btn-theme" data-bs-toggle="modal" data-bs-target="#login">
                      تسجيل الدخول
                    </button>
                  </li>
                </ul>
              </div>

              <div class="dropdown" v-if="user">
                <div class="photo" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                  <img v-if="false">
                  <img v-else src="assets/images/service-icon-01.png" height="36">
                </div>
                <ul class="dropdown-menu text-end" aria-labelledby="dropdownMenuButton">
                  <li><a class="dropdown-item"> مرحبا {{ user.name }} ! </a></li>

                  <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#editProfile">تعديل الملف الشخصى</a>
                  </li>
                  <li><a class="dropdown-item" @click.prevent="logout">تسجيل الخروج</a></li>
                </ul>
              </div>
            </div>

            <!-- Mobile Menu -->
            <div v-if="mobileMenuVisible" class="mobile-menu d-md-none" ref="mobileMenu">
              <ul class="nav flex-column">
                <li><router-link :to="{ name: 'HomePage' }">الرئيسيه</router-link></li>
                <li><router-link :to="{ name: 'AllServices' }">الخدمات</router-link></li>
                <li><router-link :to="{ name: 'AboutUs' }">معلومات عنا</router-link></li>
                <li><router-link :to="{ name: 'ConnectUs' }">تواصل معنا</router-link></li>
                <li v-if="!user">
                  <button class="btn btn-block btn-theme" data-bs-toggle="modal" data-bs-target="#login">
                    تسجيل الدخول
                  </button>
                </li>
                <li v-if="user"><a class="dropdown-item" data-toggle="modal" data-target="#editProfile">تعديل الملف
                    الشخصى</a></li>
                <li v-if="user"><a class="dropdown-item" @click.prevent="logout">تسجيل الخروج</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>


  <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="text-start">
            <h4 class="modal-title m-0 p-0" id="exampleModalLabel"> تسجيل الدخول </h4>
            <!-- <small class="text-mute">تسجيل الدخول</small> -->
          </div>
          <i class="fas fa-times p-2 text-mute" data-bs-dismiss="modal" aria-label="Close"></i>

        </div>
        <div class="modal-body text-initial">
          <form @submit.prevent="signIn">
            <div class="form-group mb-3 first">
              <label for="username">اسم المستخدم / البريد الالكترونى</label>
              <input v-model="form.email" type="text" class="form-control" placeholder="اسم المستخدم / البريد الالكترونى"
                id="email">
            </div>
            <div class="form-group mb-3 last mb-3">
              <label for="password">كلمة المرور</label>
              <input type="password" class="form-control" placeholder="كلمة المرور" id="password" v-model="form.password">
            </div>

            <div class="d-flex mb-5 align-items-center">
              <!-- <label class="control control--checkbox mb-0"><span class="caption">تذكرني!</span>
              </label> -->
              <div class="">

                <h5 class="ml-auto" data-bs-toggle="modal" data-bs-target="#forgetPassword"><a href="#"
                    class="forgot-pass">هل نسيت كلمه السر؟</a></h5>
                <h5 class="" type="h5" data-bs-toggle="modal" data-bs-target="#signUp"><a href="#">
                    تسجيل مستخدم جديد
                  </a>
                </h5>
              </div>
            </div>

            <div class="modal-footer justify-content-end">
              <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">إلغاء</button>
              <button class="btn btn-primary ml-2" type="submit">
                <i class="fas fa-play"></i> تسجيل الدخول
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
  <div class="modal fade" id="signUp" tabindex="-1" role="dialog" aria-labelledby="signUpLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="text-start">
            <h4 class="modal-title m-0 p-0" id="exampleModalLabel"> تسجيل مستخدم جديد </h4>
            <!-- <small class="text-mute">تسجيل الدخول</small> -->
          </div>
          <i class="fas fa-times p-2 text-mute" data-bs-dismiss="modal" aria-label="Close"></i>

        </div>
        <div class="modal-body text-initial">
          <form @submit.prevent="signUp">
            <div class="gender-row d-flex justify-content-around align-items-center text-algin-center">
              <div class="form-group mb-3">
                <img class="female-img gender-img" src="@/assets/Desktop/female.png" for="female" />
                <input v-model="form.gender" type="radio" id="female" name="gender" value="1"
                  class="mx-2 align-self-center" />
              </div>
              <div class="form-group mb-3">
                <img class="male-img gender-img" src="@/assets/Desktop/male.png" for="male" />
                <input v-model="form.gender" type="radio" id="male" name="gender" value="0"
                  class="mx-2 align-self-center" />
              </div>
            </div>
            <div class="user-type-row d-flex justify-content-between align-items-center text-algin-center">
              <div class="user-type">
                <label>نوع المستخدم</label>
              </div>
              <div class="form-group mb-3">
                <label for="c2">طفل</label>
                <input v-model="form.type" type="radio" id="child" name="user-type" value="0" class="mx-2" />
              </div>
              <div class="form-group mb-3">
                <label for="c2">ولي امر</label>
                <input v-model="form.type" type="radio" id="Parent" name="user-type" value="1" class="mx-2" />
              </div>

            </div>
            <div class="form-group mb-3">
              <label for="username">اسم المستخدم</label>
              <input v-model="form.name" type="text" class="form-control" placeholder="اسم المستخدم" id="username" />
            </div>
            <div class="form-group mb-3">
              <label for="email">البريد الالكتروني</label>
              <input v-model="form.email" type="text" class="form-control" placeholder="البديد الالكتروني"
                id="username" />
            </div>
            <div class="form-group mb-3">
              <label for="phone">رقم الهاتف</label>
              <input v-model="form.phone" type="text" class="form-control" placeholder="رقم الهاتف" id="phone" />
            </div>
            <div class="form-group mb-3">
              <label for="age">السن </label>
              <input v-model="form.age" type="text" class="form-control" placeholder="السن " id="age" />
            </div>
            <div class="form-group mb-3">
              <label for="hasDisability"> إذا كنت من ذوي الهمم يرجى اختيار نوع التحدي</label>
              <input v-model="form.hasDisability" type="checkbox" id="hasDisability" name="hasDisability" value="1"
                class="mx-2" />
            </div>

            <div class="form-group mb-3">
              <Multiselect v-model="form.disability_id" class="ps-3 Multiselect-opt" :noOptionsText="noOptionsText"
                mode="multiple" :multipleLabel="inMultipleLabel" :options="disabilities" :searchable="true" valueProp="id"
                label="name" track-by="id" />
            </div>
            <div class="form-group mb-3 last ">
              <label for="password">كلمة المرور</label>
              <input v-model="form.password" type="password" class="form-control" placeholder="كلمة المرور"
                id="password" />
            </div>

            <div class="modal-footer justify-content-end">
              <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">إلغاء</button>
              <button class="btn btn-primary ml-2" type="submit">
                <i class="fas fa-play"></i> تسجيل الدخول
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
  <div class="modal fade" id="editProfile" tabindex="-1" role="dialog" aria-labelledby="editProfileLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="text-start">
            <h4 class="modal-title m-0 p-0" id="exampleModalLabel"> تعديل الملف الشخصى</h4>
            <!-- <small class="text-mute">تسجيل الدخول</small> -->
          </div>
          <i class="fas fa-times p-2 text-mute" data-bs-dismiss="modal" aria-label="Close"></i>

        </div>
        <div class="modal-body text-initial">
          <form @submit.prevent="editProfile()">
            <div class="gender-row d-flex justify-content-around align-items-center text-algin-center">
              <div class="form-group mb-3">
                <img class="gender-img" src="@/assets/Desktop/female.png" for="female" />
                <input v-model="form.gender" type="radio" id="female" name="gender" value="1"
                  class="mx-2 align-self-center" checked />
              </div>
              <div class="form-group mb-3">
                <img class="gender-img" src="@/assets/Desktop/male.png" for="male" />
                <input v-model="form.gender" type="radio" id="male" name="gender" value="0"
                  class="mx-2 align-self-center" />
              </div>
            </div>
            <div class="user-type-row d-flex justify-content-between align-items-center text-algin-center">
              <div class="user-type">
                <h3>نوع المستخدم</h3>
              </div>
              <div class="form-group mb-3">
                <label for="c2">طفل</label>
                <input v-model="form.type" type="radio" id="child" name="user-type" value="0" class="mx-2" checked />
              </div>
              <div class="form-group mb-3">
                <label for="c2">ولي امر</label>
                <input v-model="form.type" type="radio" id="Parent" name="user-type" value="1" class="mx-2" />
              </div>

            </div>

            <div class="form-group mb-3">
              <label for="username">اسم المستخدم</label>
              <input v-model="form.name" type="text" class="form-control" placeholder="اسم المستخدم" id="username" />
            </div>
            <div class="form-group mb-3">
              <label for="email">البريد الالكتروني</label>
              <input v-model="form.email" type="text" class="form-control" placeholder="البديد الالكتروني"
                id="username" />
            </div>
            <div class="form-group mb-3">
              <label for="phone">رقم الهاتف</label>
              <input v-model="form.phone" type="text" class="form-control" placeholder="رقم الهاتف" id="phone" />
            </div>
            <div class="form-group mb-3">
              <label for="age">السن </label>
              <input v-model="form.age" type="text" class="form-control" placeholder="السن " id="age" />
            </div>
            <div class="form-group mb-3">
              <label for="hasDisability"> إذا كنت من ذوي الهمم يرجى اختيار نوع التحدي</label>
              <input v-model="form.hasDisability" type="checkbox" id="hasDisability" name="hasDisability" value="1"
                class="mx-2" />
            </div>

            <div class="form-group mb-3">
              <Multiselect v-model="form.disability_id" class="ps-3 Multiselect-opt" :noOptionsText="noOptionsText"
                mode="multiple" :multipleLabel="inMultipleLabel" :options="disabilities" :searchable="true" valueProp="id"
                label="name" track-by="id" />
            </div>
            <div class="form-group mb-3 last ">
              <label for="password">كلمة المرور</label>
              <input v-model="form.password" type="password" class="form-control" placeholder="كلمة المرور"
                id="password" />
            </div>

            <div class="modal-footer justify-content-end">
              <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">إلغاء</button>
              <button class="btn btn-primary ml-2" type="submit">
                <i class="fas fa-play"></i> تعديل الملف الشخصى
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
  <div class="modal fade" id="forgetPassword" tabindex="-1" role="dialog" aria-labelledby="forgetPasswordLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="text-start">
            <h4 class="modal-title m-0 p-0" id="exampleModalLabel"> هل نسيت كلمه المرور؟</h4>
            <!-- <small class="text-mute"> هل نسيت كلمه المرور؟</small> -->
          </div>
          <i class="fas fa-times p-2 text-mute" data-bs-dismiss="modal" aria-label="Close"></i>

        </div>
        <div class="modal-body text-initial">
          <form @submit.prevent="forgetPassword">
            <div class="form-group mb-3 first">
              <label for="username">البريد الالكترونى</label>
              <input v-model="form.email" type="text" class="form-control" placeholder=" البريد الالكترونى"
                id="email">
            </div>

            <div class="d-flex mb-5 align-items-center">
              <!-- <label class="control control--checkbox mb-0"><span class="caption">تذكرني!</span>
              </label> -->
              <div class="">

                <!-- <h5 class="ml-auto"><a href="#" class="forgot-pass">هل نسيت كلمه السر؟</a></h5> -->
                <h5 class="" type="h5" data-bs-toggle="modal" data-bs-target="#signUp"><a href="#">
                    تسجيل مستخدم جديد
                  </a>
                </h5>
              </div>
            </div>

            <div class="modal-footer justify-content-end">
              <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">إلغاء</button>
              <button class="btn btn-primary ml-2" type="submit">
                <i class="fas fa-play"></i>  تغير كلمه المرور
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from '@vueform/multiselect';

export default {
  name: 'AppHeader',
  components: {
    Multiselect,
  },
  data() {
    return {
      dropdownVisible: false,
      mobileMenuVisible: false,
      loading: 1,
      form: {
        name: '',
        email: '',
        password: '',
        type: '',
        disability_id: [],
        gender: null,
        status: '',
        phone: '',
        age: ''
      },
      disabilities: [],
      noOptionsText: 'لا يوجد خيارات',
      noResultsText: 'لا يوجد نتائج',
      inMultipleLabel: this.multipleLabelFunction
    };
  },
  computed: {
    ...mapGetters({
      'loginFailure': 'auth/loginFailure',
      'user': 'auth/user',

    }),

  },
  mounted() {
    this.getDisabilities();
    this.populateUserData();
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      register: 'auth/register',

    }),
    populateUserData() {
      if (this.user) {
        this.form.name = this.user.name || '';
        this.form.email = this.user.email || '';
        this.form.phone = this.user.phone || '';
        this.form.age = this.user.age || '';
        this.form.gender = this.user.gender || null;
        this.form.type = this.user.type || '';
        // this.form.disability_id = this.user.disability_id || [];
        this.form.status = this.user.status || '';
      }
    },
    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible;
    },
    closeMobileMenu() {
      this.mobileMenuVisible = false;
    },
    handleClickOutside(event) {

      const mobileMenu = this.$refs.mobileMenu;
      const menuButton = this.$refs.menuButton;
      if (
        mobileMenu &&
        !mobileMenu.contains(event.target) &&
        menuButton &&
        !menuButton.contains(event.target)
      ) {
        this.closeMobileMenu();
      }
    },
    async signIn() {

      try {
        await this.login(this.form);

        if (this.user) {
          document.querySelector('[data-bs-dismiss="modal"]').click();
 
          this.$router.replace({ name: 'AllServices' });
        } else {
          this.$router.replace({ name: 'HomePage' });
        }
      } catch (error) {
        console.error('Error during sign-in:', error);
      }
      this.closeMobileMenu();
    },
    async signUp() {
      try {
        await this.register(this.form);

        console.log("the ussssssr " + this.user);

        if (this.user) {
          this.$router.replace({ name: 'AllServices' });
    
          document.querySelector('[data-bs-dismiss="modal"]').click();
        } else {

          this.$router.replace({ name: 'HomePage' });
        }
      } catch (error) {
        console.error('Error during sign-in:', error);
      }
    },
    getDisabilities() {
      this.loading = 1;

      this.axios.get('disabilities')
        .then(response => {
          if (response.data.statue == true) {
            this.disabilities = response.data.disabilities;

          }
          this.loading = 0;
        });
    },
    multipleLabelFunction(options) {
      return `تم اختيار ${options.length} عناصر`;
    },

    editProfile() {
      this.axios.post('update_profile', this.form).then(response => {
        const res = response.data;
        if (res.status == true) {

          this.$toast.success(res.message, {
            position: 'top-right',
            duration: 4000,
            style: {
              minWidth: '300px',
              maxWidth: '600px',
            }
          });
        } else {
          this.$toast.error(res.message, {
            position: 'top-right',
          });
        }
      });
    },
    async logout() {
      try {

        await this.$store.dispatch('auth/logout');

        this.$router.replace({ name: 'HomePage' });
      } catch (error) {
        console.error('Error during logout:', error);
      }
    },
    forgetPassword() {
      this.axios.post('password/email', this.form).then(response => {
        const res = response.data;
        if (res.status == true) {

          this.$toast.success(res.message, {
            position: 'top-right',
            duration: 4000,
            style: {
              minWidth: '300px',
              maxWidth: '600px',
            }
          });
        } else {
          this.$toast.error(res.message, {
            position: 'top-right',
          });
        }
      });
    }
  },
  watch: {
    user(newVal) {
      if (!newVal) {
        this.$router.push({ name: 'HomePage' });
      }
    }
  }


};
</script>

<style scoped>
input:checked ~ .checkmark,
input.checked ~ .checkmark,
input:checked ~ .checkmark,
input.checked ~ .checkmark {
    background: #ffffff50 !important;
}
label{
  margin-bottom: 8px;
}
a {
  color: black;
}
.female-img {
  width: 70px;
}
.male-img {
  width: 60px;
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}


.header-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.nav li {
  margin-right: 20px;
}

.user-menu {
  position: relative;
  margin-left: auto;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header-area {
    justify-content: space-between;
  }

  .mobile-menu-btn {
    display: block;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .mobile-menu {
    position: absolute;
    top: 90px;
    left: 0;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 70%;
    padding: 20px;
    text-align: right;
  }

  .mobile-menu .nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .mobile-menu .nav li {
    margin-bottom: 10px;
    width: 100%;
  }
}
</style>
