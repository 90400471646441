import axios from 'axios';
export default {
    state: {
        services: [],
        subServices: [],
      },
      getters: {
        getServices: state => state.services,
        getSubServices: state => state.subServices,
      },
      mutations: {
        setServices(state, data) {
          state.services = data;
        },
        setSubServices(state, data) {
          state.subServices = data;
        },
      },
      actions: {
        async getServicesData({ commit }) {
          try {
            let response = await axios.get('category');
            if (response.data.statue == true) {
              commit('setServices', response.data.category);
            }
          } catch (error) {
            console.error('Error fetching services:', error);
          }
        },

      },

}
