import { createStore } from 'vuex'
import auth from './modules/auth'
import dataStore from './modules/dataStore'

export default createStore({
  state: {

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    dataStore,

  }
})
